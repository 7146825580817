import { configureStore, Action, AnyAction } from '@reduxjs/toolkit'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import rootReducer, { RootState } from './rootReducer'

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

const createStore = () => {
  const store = configureStore({
    reducer: rootReducer
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
      const newRootReducer = require('./rootReducer').default
      store.replaceReducer(newRootReducer)
    })
  }

  return store;
}

export default createStore;