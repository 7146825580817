// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-burrell-collection-commission-tsx": () => import("./../src/pages/burrell-collection-commission.tsx" /* webpackChunkName: "component---src-pages-burrell-collection-commission-tsx" */),
  "component---src-pages-contact-or-connect-tsx": () => import("./../src/pages/contact-or-connect.tsx" /* webpackChunkName: "component---src-pages-contact-or-connect-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-home-tsx": () => import("./../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maptracks-tsx": () => import("./../src/pages/maptracks.tsx" /* webpackChunkName: "component---src-pages-maptracks-tsx" */),
  "component---src-pages-music-tsx": () => import("./../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-press-kit-tsx": () => import("./../src/pages/press-kit.tsx" /* webpackChunkName: "component---src-pages-press-kit-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-shows-tsx": () => import("./../src/pages/shows.tsx" /* webpackChunkName: "component---src-pages-shows-tsx" */),
  "component---src-pages-storythroughvoice-chat-tsx": () => import("./../src/pages/storythroughvoice-chat.tsx" /* webpackChunkName: "component---src-pages-storythroughvoice-chat-tsx" */),
  "component---src-pages-storythroughvoice-tsx": () => import("./../src/pages/storythroughvoice.tsx" /* webpackChunkName: "component---src-pages-storythroughvoice-tsx" */),
  "component---src-pages-welcome-to-music-and-musings-tsx": () => import("./../src/pages/welcome-to-music-and-musings.tsx" /* webpackChunkName: "component---src-pages-welcome-to-music-and-musings-tsx" */),
  "component---src-templates-album-tsx": () => import("./../src/templates/album.tsx" /* webpackChunkName: "component---src-templates-album-tsx" */),
  "component---src-templates-composition-list-tsx": () => import("./../src/templates/compositionList.tsx" /* webpackChunkName: "component---src-templates-composition-list-tsx" */),
  "component---src-templates-journal-entry-tsx": () => import("./../src/templates/journalEntry.tsx" /* webpackChunkName: "component---src-templates-journal-entry-tsx" */),
  "component---src-templates-journal-list-tsx": () => import("./../src/templates/journalList.tsx" /* webpackChunkName: "component---src-templates-journal-list-tsx" */),
  "component---src-templates-project-list-tsx": () => import("./../src/templates/projectList.tsx" /* webpackChunkName: "component---src-templates-project-list-tsx" */),
  "component---src-templates-project-tsx": () => import("./../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

