import { createSlice, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { Howl } from 'howler';
import { RootState } from '../rootReducer';

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const playStates = {
  paused: 'paused',
  playing: 'playing',
  stopped: 'stopped',
  loading: 'loading',
};

export const volumeStates = {
  muted: 'muted',
  unmuted: 'unmuted',
};

let activeTrack: Howl | undefined = undefined;

const { actions: playerActions, reducer: playerReducer } = createSlice({
  name: 'player',
  initialState: {
    queue: [
      {
        title: 'Father Father',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/7af85102af8481f216070be2db0b7db87bee6d51.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/f94cf7cb23753ce0df9552f590c5cb2732ccaa27.mp3',
        ],
      },
      {
        title: 'Opium and Smoke',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/e737af6a2816c5f24e34408da44efd38d67af963.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/3538c88a9991f45d7231abeec723b3da0f05b963.mp3',
        ],
      },
      {
        title: 'Spider',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/0acae34f2f0d8e5826918296aaf3478ed3f226f6.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/d960d88f07b6e29727671f5ed7d8c968e6f50df6.mp3',
        ],
      },
      {
        title: 'The Moorland Witch',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/64f5de66b8f71f861faee2692c7c92c2c7ec571d.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/0293b3f32eb7639b6b5aae85be094c3afdf12206.mp3',
        ],
      },
      {
        title: 'She Moved Through the Fair',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/cefa55de5c095762e3d81bcfcca81088dacc0a20.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/518a6dfc6c4b01f1cf7ae15725ac9384b66b7851.mp3',
        ],
      },
      {
        title: 'Swallow',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/adb81835018b7e4db72f9db9f359a3f749a26f89.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/32221e0c41133b0eec9e381c4bda10527333fa7f.mp3',
        ],
      },
      {
        title: '1919',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/ea48644b0092098502f393713c93a658e3264ada.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/4b1dd00a720be5dbe70145df16428eb6361da06b.mp3',
        ],
      },
      {
        title: 'Just a Drop',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/999194ebb7647bfa9e07fd830eb5122dbf1ac02b.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/54c5ad2b21740dbafa9c433c2761dc5f915f6eeb.mp3',
        ],
      },
      {
        title: 'Down by the Salley Gardens',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/d87088259ddf534fac8e8d3c67f370a54cab27e1.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/7749d3a4cbf25509d588ef133f457867acbce390.mp3',
        ],
      },
      {
        title: 'Mechanical Animal',
        sources: [
          'https://cdn.sanity.io/files/gotgzth4/production/2c10733ae6b1d5b23d575f9ff821185c6085a480.webm',
          'https://cdn.sanity.io/files/gotgzth4/production/4ba54e5d61e5c6b5a0a6db3ec21d4e4da6142782.mp3',
        ],
      },
    ],
    queuePosition: 1,
    playState: playStates.stopped,
    volumeLevel: 0.8,
    volumeState: volumeStates.unmuted,
    nowPlaying: undefined,
  },
  reducers: {
    play: state => {
      state.playState = playStates.playing;
    },
    pause: state => {
      state.playState = playStates.paused;
    },
    load: state => {
      state.playState = playStates.loading;
    },
    stop: state => {
      if (state.playState !== playStates.loading) {
        state.playState = playStates.stopped;
      }
    },
    mute: state => {
      state.playState = volumeStates.muted;
    },
    unmute: state => {
      state.playState = volumeStates.unmuted;
    },
    setVolume: (state, action) => {
      state.volumeLevel = action.payload;
    },
    setQueuePosition: (state, action) => {
      state.queuePosition = action.payload;
    },
    setNowPlaying: (state, action) => {
      state.nowPlaying = action.payload;
    },
    clearQueue: state => {
      state.queue = [];
    },
    addToQueue: (state, action) => {
      state.queue.push(action.payload);
    },
    removeFromQueue: (state, action) => {
      state.queue.splice(action.payload, 1);
    },
    nextInQueue: state => {
      if (state.queuePosition + 1 < state.queue.length) {
        state.queuePosition = state.queuePosition + 1;
      } else {
        state.queuePosition = state.queue.length - 1;
      }
    },
    previousInQueue: state => {
      if (state.queuePosition - 1 > 0) {
        state.queuePosition = state.queuePosition - 1;
      } else {
        state.queuePosition = 0;
      }
    },
    setQueue: (state, action) => {
      state.queuePosition = action.payload.queuePosition;
      state.queue = action.payload.tracks;
    },
  },
});

const loadTrack = (
  track,
  shouldPlay = true,
  initialLoad = false
): AppThunk => async dispatch => {
  dispatch(playerActions.load());

  const trackPlayer = new Howl({
    autoplay: false,
    preload: shouldPlay,
    html5: true,
    src: track.sources,
    onplay: () => dispatch(playerActions.play()),
    onend: () => dispatch(next()),
    onpause: () => dispatch(playerActions.pause()),
    onstop: () => dispatch(playerActions.stop()),
    onmute: () => dispatch(playerActions.mute()),
    onvolume: (_id, volume) => {
      dispatch(playerActions.setVolume(volume));
    },
    onload: () => {
      if (!shouldPlay) {
        dispatch(playerActions.pause());
      }
    },
  });

  if (initialLoad) {
    dispatch(playerActions.pause());
  }

  if (activeTrack) {
    activeTrack.unload();
  }

  activeTrack = trackPlayer;

  if (shouldPlay) {
    activeTrack.play();
  }
};

export function play() {
  if (activeTrack) {
    activeTrack.load().play();
  }
  return { type: 'noop' };
}

export function pause() {
  if (activeTrack) {
    activeTrack.pause();
  }
  return { type: 'noop' };
}

export function next() {
  return (dispatch, getState) => {
    const { player } = getState();
    const { queue, queuePosition } = player;
    const track = queue[queuePosition + 1];

    if (!track) {
      dispatch(playerActions.stop());
    } else {
      dispatch(loadTrack(track));
      dispatch(playerActions.nextInQueue());
    }
  };
}

export function previous() {
  return (dispatch, getState) => {
    const { player } = getState();
    const { queue, queuePosition } = player;
    const track = queue[queuePosition - 1];

    if (!track) {
      dispatch(playerActions.stop());
    } else {
      dispatch(loadTrack(track));
      dispatch(playerActions.previousInQueue());
    }
  };
}

export function replaceQueue({ tracks = [], queuePosition = 0 }) {
  return dispatch => {
    const track = tracks[queuePosition];

    if (!track) {
      dispatch(playerActions.stop());
    } else {
      dispatch(loadTrack(track));
      dispatch(playerActions.setQueue({ tracks, queuePosition }));
    }
  };
}

export function initialisePlayer() {
  return (dispatch, getState) => {
    const { player } = getState();
    const { queue, queuePosition } = player;
    const track = queue[queuePosition];
    if (!activeTrack) {
      if (!track) {
        dispatch(stop());
      } else {
        dispatch(loadTrack(track, false, true));
      }
    }
  };
}

export const actions = {
  ...playerActions,
  play,
  pause,
  next,
  previous,
  replaceQueue,
  initialisePlayer,
};

export default playerReducer;
